// ConfidentialWrapper for cookies
import React from "react";

import { ConfidentialsContext } from "../constants";
import { useSidebarState } from "../hooks/useCookies";

const ConfidentialsWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const nextState = useSidebarState();
  return (
    <ConfidentialsContext.Provider value={
      { sidebarState: nextState }
    }
    >
      {children}
    </ConfidentialsContext.Provider>
  );
};

export default ConfidentialsWrapper;
