import { createContext } from "react";

export const SidebarCookieName = "sidebar-modal";
export enum SidebarStates {
  Intro = "intro",
  Prompt = "prompts",
}
export const SidebarStateOptions = [SidebarStates.Intro, SidebarStates.Prompt] as const;
type ConfidentialsContextType = {
  sidebarState: null | SidebarStates;
};

export const ConfidentialsContext = createContext<ConfidentialsContextType>({
  sidebarState: null,
});
