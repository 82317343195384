import { ComponentType, useEffect } from "react";

const withScrollToTop = (WrappedComponent: ComponentType) => {
  const WithScrollToTop = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return <WrappedComponent />;
  };
  return WithScrollToTop;
};

export default withScrollToTop;
