import { List, ListItem, ListItemText } from "@mui/material";
import TermsAndPoliciesLayout from "./components/TermsAndPoliciesLayout";
import withScrollToTop from "../../helpers/ScrollToTop";

function LegalDisclaimer() {
  return (
    <TermsAndPoliciesLayout type="disclaimer" headerTitle="Legal Disclaimer">
      This legal disclaimer governs the utilization of TradeGPT by TA Fintech
      Inc., Chatbot ("Chatbot") and delineates the terms and conditions under
      which users may access and interact with the Chatbot. By engaging with the
      Chatbot, users expressly acknowledge their assent to be bound by the
      stipulations set forth herein. Should users dissent from any part of this
      disclaimer, they are hereby advised to refrain from utilizing the Chatbot.
      <List
        sx={{
          listStyle: "decimal",
          fontStyle: {
            fontFamily: "Inter, sans-serif",
            fontWeight: "bold",
            fontSize: 18,
            color: "#1C1C1E",
          },
        }}
      >
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  General Information Purposes Only:
                </span>
                {" "}
                The Chatbot is meticulously designed exclusively for general
                informational purposes and is categorically not intended to
                dispense legal, medical, financial, or any other professional
                advice. Users are explicitly cautioned against relying solely on
                the information proffered by the Chatbot and are strongly
                encouraged to seek professional counsel for specific queries or
                concerns.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">No Guarantee of Accuracy:</span>
                {" "}
                The
                Chatbot is proffered on an "as is" and "as available" basis,
                devoid of any express or implied representations or warranties.
                TA Fintech Inc. expressly disclaims any assurance regarding the
                accuracy, completeness, or utility of the information
                disseminated by the Chatbot. Users acknowledge that the
                information furnished by the Chatbot may be subject to
                inadvertent errors, inaccuracies, or omissions.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">Third-Party Links:</span>
                {" "}
                The
                Chatbot may incorporate links to external third-party websites
                or resources, the accessibility and content of which lie beyond
                the purview of TA Fintech Inc.. Inclusion of such links does not
                constitute an endorsement by TA Fintech Inc., and users are
                urged to exercise due diligence in accessing third-party
                content.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Information Gathering and Potential Inaccuracy:
                </span>
                {" "}
                The Chatbot may engage in the collection and processing of user
                data for the purpose of refining its functionality and enhancing
                user experience. While diligent efforts are made to provide
                accurate information, users acknowledge the potential for
                inaccuracies or omissions in the information generated by the
                Chatbot and are advised to independently verify crucial
                information.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">User Responsibilities:</span>
                {" "}
                Users
                bear the onus of the decisions derived from the information
                furnished by the Chatbot. It is imperative for users to exercise
                prudence and seek professional advice tailored to their specific
                circumstances.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">Limitation of Liability:</span>
                {" "}
                TA
                Fintech Inc. disclaims all liability for direct, indirect,
                incidental, special, or consequential damages arising from or
                connected with the use of the Chatbot, including but not limited
                to, damages for loss of profits, data, or other intangible
                losses.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">Data Privacy:</span>
                {" "}
                The Chatbot may
                engage in the collection and processing of user data in
                conformity with our Privacy Policy, aimed at refining its
                functionality and enhancing user experience. Users, by virtue of
                utilizing the Chatbot, manifest their consent to the collection
                and processing of their data in accordance with said Privacy
                Policy.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">Intellectual Property:</span>
                {" "}
                All
                intellectual property rights inherent in and appertaining to the
                Chatbot, including but not confined to copyrights, trademarks,
                and trade secrets, vest exclusively with TA Fintech Inc.. Users
                are expressly precluded from utilizing or appropriating such
                intellectual property without our explicit, written consent.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">Changes to the Disclaimer:</span>
                {" "}
                We
                expressly reserve the prerogative to effect modifications or
                updates to this legal disclaimer sans prior notice. Users are
                strongly urged to periodically review this page to remain
                apprised of any alterations.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">Contact Information:</span>
                {" "}
                For
                queries or concerns pertaining to this legal disclaimer, users
                are invited to communicate with TA Fintech Inc. through the
                contact information provided herein.
              </p>
            )}
          />
        </ListItem>
      </List>
      By accessing and utilizing the Chatbot, users attest to having perused,
      comprehended, and assented to abide by the stipulations enunciated within
      this legal disclaimer in compliance with the applicable laws and
      regulations in your jurisdiction/country.
    </TermsAndPoliciesLayout>
  );
}
export default withScrollToTop(LegalDisclaimer);
