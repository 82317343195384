// ConfidentialWrapper for cookies
import React, { useEffect } from "react";

import { sendMessage } from "../../redux/slices/websocketSlice";
import { AppDispatch } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

const WebsocketWrapper: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: { auth }) => state?.auth?.currentUser);

  const user_id = user?.userId || "";
  useEffect(() => {
    if (user_id) {
      dispatch(sendMessage([`ACTIVE:${user_id}`]));
    }
  }, [user_id]);
  return children;
};

export default WebsocketWrapper;
