import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams, Outlet } from "react-router-dom";
import { injectCjPageVisit } from "../components/Session/helpers";
import { logPageView } from "../analytics";
import { getDynamicPathObject } from "../helpers";

const LocationListener = () => {
  const location = useLocation();
  const params = useParams();
  const user = useSelector((state: { auth }) => state?.auth?.currentUser);
  const user_id = user?.userId || "";
  const handleLocationChange = useCallback((location, params) => {
    const email = user?.email || "";
    const pathname = location.pathname;
    // Add your custom logic here, such as dispatching actions or logging
    if (pathname === "/") {
      injectCjPageVisit("homepage", "N/A", email);
    }
    else if (pathname.includes("chat")) {
      injectCjPageVisit("applicationStart", user_id, email);
    }
    else if (pathname.includes("plans")) {
      injectCjPageVisit("productDetail", user_id, email);
    }
    else if (pathname.includes("signup") || pathname.includes("signin")) {
      injectCjPageVisit("accountSignup", "N/A", email);
    }
    const path = getDynamicPathObject(pathname, params);
    logPageView(path, params);
  }, []);

  useEffect(() => {
    handleLocationChange(location, params);
    // not including params in deps because useParams returns a new object on every render
  }, [location]);

  return <Outlet />;
};

export default LocationListener;
