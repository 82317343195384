import { IconChevronLeftLightThin } from "./icons/IconChevronLeftLightThin";
import { IconsIconGraphFilled } from "./icons/IconsIconGraphFilled";
import { IconsIconOptions1 } from "./icons/IconsIconOptions1";
import { IconsIconScreener } from "./icons/IconsIconScreener";
import { IconsIconTrendingUp1 } from "./icons/IconsIconTrendingUp1";
import PlanCard from "./PlanCard";
import { RootState } from "../../assets/interfaces/interfaces";
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { AppDispatch } from "../../redux/store";
import { fetchActivePlan } from "../../redux/slices/activePlanSlice";
import moment from "moment";
import useEnhancedNavigate from "../Session/helpers";
import PricingToggle from "./YearlyToggle";
import Footer from "./Footer";

// const event_id_lookup = {
//   "TradeGpt-Premium-USD-Yearly": 5,
//   "TradeGpt-Pro-USD-Yearly": 4,
//   "TradeGpt-Premium-USD-Monthly": 3,
//   "TradeGpt-Pro-USD-Monthly": 2,
//   "free": 1,
// };
// const price_lookup = {
//   "TradeGpt-Premium-USD-Yearly": 2995,
//   "TradeGpt-Pro-USD-Yearly": 295,
//   "TradeGpt-Premium-USD-Monthly": 299,
//   "TradeGpt-Pro-USD-Monthly": 29,
//   "free": 0,
// };
const items = [
  {
    id: 1,
    title: "Charts",
    description:
      "Visualization of financial data, stock price movements, volume trends, and technical indicators.",
    IconComponent: IconsIconGraphFilled, // Replace with actual SVG component
  },
  {
    id: 2,
    title: "Stock Screener on 10K Stocks",
    description:
      "Identify stocks based on specific criteria like market capitalization, sector, financial metrics, etc.",
    IconComponent: IconsIconScreener, // Replace with actual SVG component
  },
  {
    id: 3,
    title: "Top Gainers and Losers",
    description:
      "Identify stocks that have the most significant price increases (gainers) and decreases (losers) over a specified time frame.",
    IconComponent: IconsIconTrendingUp1, // Replace with actual SVG component
  },
  {
    id: 4,
    title: "Company Financials Data",
    description:
      "Obtain Income statements, balance sheets, cash flow statements, and key financial ratios.",
    IconComponent: "https://c.animaapp.com/4q2YREBC/img/svg-6.svg", // Replace with actual SVG URL or component
  },
  {
    id: 5,
    title: "Options Data",
    description:
      "Get options contracts, including strike prices, expiration dates, put/call ratios, and volume.",
    IconComponent: IconsIconOptions1, // Replace with actual SVG component
  },
  {
    id: 6,
    title: "Macroeconomic US Stocks Data",
    description:
      "Data and analysis on how macroeconomic factors (like GDP growth, interest rates, inflation, etc.) impact U.S. stock markets.",
    IconComponent: "https://c.animaapp.com/4q2YREBC/img/svg-6.svg", // Replace with actual SVG URL or component
  },
  {
    id: 7,
    title: "Analyst Estimates",
    description:
      "Forecasts and recommendations from financial analysts on stock performance, including target prices and earnings projections.",
    IconComponent: "https://c.animaapp.com/4q2YREBC/img/svg-6.svg", // Replace with actual SVG URL or component
  },
  {
    id: 8,
    title: "Stock Prices (OHLC)",
    description:
      "Historical Open, High, Low, and Close (OHLC) prices of stocks.",
    IconComponent: "https://c.animaapp.com/4q2YREBC/img/svg-6.svg", // Replace with actual SVG URL or component
  },
  {
    id: 9,
    title: "Super Investor Holdings",
    description:
      "Information on the stock holdings of renowned investors and hedge funds.",
    IconComponent: "https://c.animaapp.com/4q2YREBC/img/svg-6.svg", // Replace with actual SVG URL or component
  },
  {
    id: 10,
    title: "Insider Trading",
    description:
      "Buying and selling activities of a company's insiders, such as executives, directors, and major shareholders.",
    IconComponent: "https://c.animaapp.com/4q2YREBC/img/svg-6.svg", // Replace with actual SVG URL or component
  },
  {
    id: 11,
    title: "Earnings Calendar",
    description:
      "Schedule of when companies will release their earnings reports.",
    IconComponent: "https://c.animaapp.com/4q2YREBC/img/svg-6.svg", // Replace with actual SVG URL or component
  },
];

const renderIcon = (icon) => {
  if (typeof icon === "string") {
    // It's a URL, render an img tag
    return (
      <img src={icon} alt="icon" className="!relative !w-[32px] !h-[32px]" />
    );
  }
  else {
    // It's a component, render it directly
    const IconComponent = icon;
    return (
      <IconComponent className="!relative !w-[32px] !h-[32px]" color="white" />
    );
  }
};
const Plans = ({
  allPlans,
  yearly,
  setYearly,
}: {
  allPlans;
  yearly: boolean;
  setYearly: (boolean) => void;
}) => {
  const navigate = useEnhancedNavigate();
  const user = useSelector((state: RootState) => state.auth.currentUser);
  const dispatch: AppDispatch = useDispatch();
  const { plan } = useSelector((state: { plan }) => state.plan);
  const nonRenewing = useMemo(() => {
    return !!plan?.non_renewing;
  }, [plan]);
  const exp = useMemo(() => {
    if (!user || !nonRenewing) return false;
    return moment(plan?.subscription?.expires_at).format("DD MMM YYYY");
  }, [plan]);

  const handleBackPress = () => {
    // Lets get the query params and see if it coming from the app
    const comesFromApp = window.location.search.includes("tradegpt-app");
    if (comesFromApp) {
      // Get back to app
      window.location.replace("tradegpt://");
    }
    navigate("/chat");
  };

  useEffect(() => {
    let timeout;
    // Ensure Chargebee script is loaded and available
    if (window.Chargebee) {
      window.Chargebee.registerAgain();
      const { email, display_name, phoneNumber } = user;
      const cbInstance = window.Chargebee.getInstance();
      // Event listener for checkout success
      cbInstance.setCheckoutCallbacks(function (
        // cart
      ) {
        // const product_id = cart.products[0].items[0].item_price_id;
        // const queuedPlan = event_id_lookup[product_id];
        // const amount = price_lookup[product_id];
        // const items = cart.products[0].items;
        // you can define a custom callbacks based on cart object
        return {
          loaded: function () {
            // checkout opened
          },
          close: function () {
            // fetch updated plan status
            // Start the timeout in the close callback
            timeout = setTimeout(() => {
              dispatch(fetchActivePlan());
            }, 3500);
          },
          success: function (
            // orderId
          ) {
            // injectTrackingJS(queuedPlan);
            // const cookies = new Cookies();
            // const cjeCookie = cookies.get("cje");
            // console.log({ orderId });
            // if (cjeCookie) {
            //   injectCjConversion("464053", amount, userId, email, cjeCookie, orderId, items);
            // }
          },
          step: function () {
            // value -> which step in checkout
          },
        };
      });
      const cart = cbInstance.getCart();
      cart.setCustomer({ email, first_name: display_name, phone: phoneNumber });
      cbInstance.setPortalSession(() => {
        const url = `${process.env.REACT_APP_SPL_URL}/chargebee/portal-session`;
        return axios
          .post(url, {}, { withCredentials: true })
          .then(res => res.data);
      });
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout); // Clear the timeout when the component unmounts
      }
    };
  }, [user, yearly]);

  const currentPlanName = useMemo(() => {
    return allPlans.find(plan => plan.currentPlan).planName;
  }, [allPlans]);
  useEffect(() => {
    // Set the body class when the component mounts
    document.body.classList.add("!bg-white");

    // Clean up by removing the body class when the component unmounts
    return () => {
      document.body.classList.remove("!bg-white");
    };
  }, []);
  return (
    <div className="justify-center w-full p-0 md:p-14 md:pb-0">
      <div
        onClick={handleBackPress}
        className="cursor-pointer inline-flex items-start gap-[4px] pt-4 pl-4 md:p-[40px] md:absolute top-0 left-0"
      >
        <IconChevronLeftLightThin className="!relative !w-[24px] !h-[24px] svg-left" />
        <div className="relative w-fit top-[1px] font-body-small-regular font-[number:var(--body-small-regular-font-weight)] text-colorstextlightprimary text-[length:var(--body-small-regular-font-size)] text-center tracking-[var(--body-small-regular-letter-spacing)] leading-[var(--body-small-regular-line-height)] whitespace-nowrap [font-style:var(--body-small-regular-font-style)] sub-back-button">
          Back to TradeGPT
        </div>
      </div>
      {/* Hero Section */}
      <div className="flex flex-col items-center gap-[3px] p-10">
        <p className="relative w-fit mt-[-1.00px] font-h2-h1-bold font-[number:var(--h2-h1-bold-font-weight)] text-colorstextlightprimary text-[length:var(--h2-h1-bold-font-size)] text-center tracking-[var(--h2-h1-bold-letter-spacing)] leading-[var(--h2-h1-bold-line-height)] whitespace-wrap [font-style:var(--h2-h1-bold-font-style)] sub-title">
          Unlock the power of TradeGPT
        </p>
        <p className="max-w-[787px] relative flex-1 mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-colorstextlightprimary text-[16px] text-center tracking-[0] leading-[24px] sub-info">
          Our pricing is accessible to all traders. Choose the option that&#39;s
          right for you. If you trade infrequently or are taking a break, you
          can switch plans at any time.
        </p>
      </div>
      <div className="toggle-wrapper">
        <PricingToggle pricingPlan={yearly} setPricingPlan={setYearly} />
      </div>
      {/* Plans Section */}
      <div className="flex mt-13 flex-wrap gap-5 justify-center">
        {allPlans.map((plan, index) => (
          <PlanCard
            yearly={yearly}
            exp={exp}
            nonRenewing={nonRenewing}
            currentPlanName={currentPlanName}
            key={plan.id ? plan.id : `plan-${index}`}
            {...plan}
          />
        ))}
      </div>
      {/* Features Section */}
      <div className="max-w-[1300px] mt-28 flex flex-wrap justify-center gap-5 mx-auto ">
        <div className="flex w-full items-center justify-center text-[#525252] text-center font-inter text-[16px] font-normal leading-[22px] tracking-tighter plans">
          All Plans Include:
          {" "}
        </div>
        {items.map(item => (
          <div
            key={item.id}
            className="w-[300px] h-[222px] flex flex-col items-start bg-colorsfilllighttertiary rounded-[12px] p-6 max-w-sm"
          >
            <div className="mb-4">{renderIcon(item.IconComponent)}</div>
            <h3 className="mb-2 text-lg font-semibold text-colorstextlightsecondary whitespace-nowrap">
              {item.title}
            </h3>
            <p className="text-sm text-colorstextlightquaternary">
              {item.description}
            </p>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Plans;
