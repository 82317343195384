import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import TradeLogo from "../assets/images/finchatGPT/TradeLogo-D.png";
import Link from "@mui/material/Link";
import LandingBg from "../assets/videos/landing.mp4";
import useEnhancedNavigate, { injectTikTokScript, removeTikTokScript } from "./Session/helpers";

const LandingPage: React.FC = () => {
  const [taglineIndex, setTaglineIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const navigate = useEnhancedNavigate(); // Use the enhanced navigate function
  const taglines = [
    `Empower Your Trading, Decisions with AI-, Driven Insights`,
    `Access Market Data &, Trends at Your, Fingertips`,
    `Navigate the Financial, Markets with, Confidence and, Precision`,
  ];
  const taglineSegments = taglines.map(tagline => tagline.split(","));
  useEffect(() => {
    const totalLength = taglineSegments[taglineIndex].reduce((acc, segment, idx) => {
      return acc + segment.length + (idx < taglineSegments[taglineIndex].length - 1 ? 1 : 0);
    }, 0);

    if (charIndex < totalLength) {
      const timer = setTimeout(() => {
        setCharIndex(charIndex + 1);
      }, 70); // Adjust typing speed as needed
      return () => clearTimeout(timer);
    }
  }, [charIndex, taglineIndex, taglineSegments]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTaglineIndex(prevIndex => (prevIndex + 1) % taglines.length);
      setCharIndex(0);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [taglines.length, taglineIndex, taglineSegments]);

  const handleLoginClick = () => {
    navigate("/signin");
  };
  const handleSignUpClick = () => {
    navigate("/signup");
  };
  const handleLogoClick = () => {
    navigate("/");
  };

  useEffect(() => {
    // Inject the script when the component mounts
    injectTikTokScript();
    return () => {
      // Remove the script when the component unmounts
      removeTikTokScript();
    };
  }, []);

  const renderAnimatedTagline = () => {
    const segments = taglineSegments[taglineIndex];
    let renderedText = "";
    let currentIndex = 0;

    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      if (currentIndex + segment.length < charIndex) {
        renderedText += segment + (i < segments.length - 1 ? "," : "");
        currentIndex += segment.length + 1; // +1 for the comma
      }
      else {
        renderedText += segment.substring(0, charIndex - currentIndex);
        break;
      }
    }

    return renderedText.split(",").map((part, index) => (
      <span key={index}>
        {part}
        {index < segments.length - 1 && <br />}
      </span>
    ));
  };

  return (
    <div className="flex flex-col min-h-screen w-full bg-white overflow-hidden">
      <div className="flex flex-grow">
        <div className="flex flex-col w-full lg:w-1/2">
          {/* Header Section with Logo and Text */}
          <div className="flex items-center p-5 cursor-pointer" onClick={handleLogoClick}>
            <img src={TradeLogo} alt="TradeLogo" className="h-8 mr-2" />
            <span className="text-[20px] font-bold">
              TradeGPT
            </span>
          </div>
          <div className="flex h-full items-center justify-center">
            <div className="flexcol justify-center">
              {/* Get Started text */}
              <div className="flex flex-col justify-start items-center pt-8">
                <span className="text-[#1C1C1E] text-center custom-font text-[28px] font-bold leading-[30px]">
                  Get Started
                </span>
              </div>
              <div className="pt-10 flex flex-col justify-start items-center gap-6">
                {/* Buttons */}
                <Button onClick={handleLoginClick} variant="contained" className="w-[295px] h-[50px] text-white text-[16px] font-medium leading-[30px] tracking-[-0.32px] !normal-case !bg-[#1C1C1E]">
                  Log In
                </Button>
                <Button onClick={handleSignUpClick} variant="contained" className="w-[295px] h-[50px] text-white text-[16px] font-medium leading-[30px] tracking-[-0.32px] !normal-case !bg-[#1C1C1E]">
                  Sign Up
                </Button>
              </div>
            </div>
          </div>
          <footer className="flex justify-center items-center mt-auto p-6">
            <span className=" text-[#A3A3A3] hover:underline">
              <Link href="/policies/service-terms" color="inherit" underline="none">
                Terms of Use
              </Link>
            </span>
            <div className="px-4 text-black">
              |
            </div>
            <span className=" text-[#A3A3A3] hover:underline">
              <Link href="/policies/privacy-policy" color="inherit" underline="none">
                Privacy Policy
              </Link>
            </span>
          </footer>
        </div>
        <div className="hidden relative overflow-hidden md:flex md:w-1/2 justify-center items-center bg-black">
          <div className="p-16 absolute top-0 bottom-0 z-10 left-0">
            <div className="flex justify-center items-center h-full">
              <div className="text-white text-3xl lg:text-5xl lg:leading-snug animated-tag">
                {renderAnimatedTagline()}
              </div>
            </div>
          </div>
          <video className="scale-[120%] h-full object-cover" autoPlay loop muted playsInline>
            <source src={LandingBg} type="video/mp4" />
          </video>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40"></div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
