import { ReactComponent as LogoFilledFull } from "../Icons/LogoFilledFull.svg";
import { Button, ButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <div className="bg-white py-12 flex flex-col items-center">
      <div className="flex items-center justify-center pb-6">
        <LogoFilledFull />
        <h2 className=" text-xl font-semibold ml-3">TradeGPT</h2>
      </div>
      <ButtonGroup color="inherit" orientation="horizontal" variant="text">
        <Button
          size="medium"
          className="button-link"
          onClick={() => navigate("/policies/service-terms")}
        >
          Terms & Policies
        </Button>
        <Button
          size="medium"
          className="button-link"
          onClick={() => navigate("/policies/privacy-policy")}
        >
          Private Policy
        </Button>
        <Button
          size="medium"
          className="button-link"
          onClick={() => navigate("/policies/legal-disclaimer")}
        >
          Legal Disclaimer
        </Button>
      </ButtonGroup>
    </div>
  );
}
