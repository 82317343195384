import { SidebarCookieName, SidebarStateOptions, SidebarStates } from "../constants";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export const useSidebarState = (): SidebarStates => {
  const sidebarCookie = cookies.get(SidebarCookieName);
  const currentIndex = SidebarStateOptions.indexOf(sidebarCookie);
  const nextStateIndex = !sidebarCookie ? 0 : (currentIndex + 1) % SidebarStateOptions.length;
  const nextState = SidebarStateOptions[nextStateIndex];
  cookies.set(SidebarCookieName, SidebarStateOptions[nextStateIndex], { path: "/" });
  return nextState;
};
