import React, { useCallback } from "react";
import { Modal, Button } from "@mui/material";
import PromptLogo from "../assets/images/finchatGPT/Prompts.png";
import IntroPromo from "../assets/images/finchatGPT/IntroPromo.png";
import { SidebarStates } from "../constants";

interface SidebarPromoModalProps {
  open: boolean;
  handleClose: () => void;
  promo: SidebarStates;
}

const SidebarPromoModal: React.FC<SidebarPromoModalProps> = ({
  open,
  handleClose,
  promo,
}) => {
  const handleClick = useCallback(() => {
    let url;
    switch (promo) {
      case SidebarStates.Prompt:
        url
          = "https://go.tradealgo.com/tradegpt-prompts?ads_source=Platform_TradeGPT_Prompts";
        break;
      case SidebarStates.Intro:
        url = "https://reports.tradersalgo.com/webinar-tradegpt-members/";
        break;
      default:
        console.error(`Invalid promot ${promo}`);
        return;
    }
    window.open(url, "_blank");
  }, [promo]);
  const renderContent = () => {
    switch (promo) {
      case SidebarStates.Prompt:
        return (
          <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
            {/* Modal header */}
            <div className="bg-black pt-10 pb-4 rounded-t-lg">
              <img
                src={PromptLogo}
                alt="PromptsLogo"
                className="h-auto w-32 mx-auto"
              />
            </div>
            <div className="text-center rounded-t-lg">
              <h3 className="text-3xl font-bold text-black pt-6 pb-4 px-10">
                Get 100+ TradeGPT Prompts For More Profitable Trading FREE!
              </h3>
            </div>
            <hr className="border-t border-gray-300 mx-auto w-2/12" />
            {/* Modal body */}
            <div className="pt-6 pb-4 px-10">
              <p className="text-sm text-center text-gray-600">
                Supercharge your TradeGPT experience with our
              </p>
              <p className="text-sm text-center text-gray-600">
                <b>"TradeGPT 100+ Prompts"</b>
                {" "}
                – the ultimate ebook for prompt
              </p>
              <p className="text-sm text-center text-gray-600">
                mastery. Whether you're a beginner or a seasoned trader, this
              </p>
              <p className="text-sm text-center text-gray-600">
                guide unlocks the secrets to crafting powerful prompts,
              </p>
              <p className="text-sm text-center text-gray-600">
                optimizing interactions, and maximizing TradeGPT's potential.
              </p>
              <p className="text-sm text-center text-gray-600 pt-4">
                Download now to elevate your trading game with step-by-step
              </p>
              <p className="text-sm text-center text-gray-600">
                instructions and valuable insights. Your journey to prompt
              </p>
              <p className="text-sm text-center text-gray-600">
                mastery begins here – seize the opportunity for success!
              </p>
            </div>
            {/* Modal footer */}
            <div className="flex flex-col items-center justify-center p-6 space-y-4">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#007BFF",
                  color: "white",
                  textTransform: "none",
                  width: 270,
                }}
                onClick={handleClick}
              >
                Get Instant Access
              </Button>
            </div>
          </div>
        );
      case SidebarStates.Intro:
        return (
          <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
            <img
              src={IntroPromo}
              alt="F1Logo"
              className="h-auto w-full rounded-t-lg"
            />
            {/* Modal header */}
            <div className="text-center">
              <h3 className="text-3xl font-bold px-10 text-black py-6">
                LEARN HOW TO MAKE MONEY WITH TRADEGPT
              </h3>
            </div>
            <hr className="border-t border-gray-300 mx-auto w-2/12" />
            {/* Modal body */}
            <div className="py-5  px-11">
              <p className="text-sm text-center text-gray-600">
                Learn to
                <b> boost your trading profits </b>
                with our free
                TradeGPT training. This session covers how to use TradeAlgo's
                AI, TradeGPT, to access instant data, analysis, and trade
                recommendations.
              </p>
              <p className="text-sm text-center text-gray-600 pt-4">
                Our experts will demonstrate effective tools and strategies for
                all traders, including beginners. Ideal for options traders and
                day traders interested in using AI. Check out the webinar for
                practical insights into AI-powered trading.
              </p>
            </div>
            {/* Modal footer */}
            <div className="flex flex-col items-center justify-center pt-4 pb-6">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#007BFF",
                  color: "white",
                  textTransform: "none",
                  width: 270,
                }}
                onClick={handleClick}
              >
                Register Now
              </Button>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="flex items-center justify-center p-4 sm:p-0"
    >
      {renderContent()}
    </Modal>
  );
};

export default SidebarPromoModal;
