import { useSelector } from "react-redux";
import {

  Navigate,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import LandingPage from "./LandingPage";

const AuthRoutes = () => {
  const authLoading = useSelector((state: { auth }) => state.auth.loading);
  const user = useSelector((state: { auth }) => state.auth.currentUser);

  const isUserSignedIn = !!user && !authLoading;

  if (authLoading) return <></>;

  return isUserSignedIn
    ? (
        <Navigate to={`/chat/${uuidv4()}`} />
      )
    : (
        <LandingPage />
      );
};

export default AuthRoutes;
