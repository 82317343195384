// AppWrapper.js
import React, { ReactNode, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NotificationSnackbar from "./Snackbar";
import { hideNotification } from "../redux/slices/notificationSlice";

interface AppWrapperProps {
  children: ReactNode; // This type allows for any renderable React content
}
// Global config like theme/lanugauage/timezone etc
const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
  const dispatch = useDispatch();
  const notification = useSelector((state: { notification }) => state.notification);
  const handleSnackbarClose = () => {
    dispatch(hideNotification());
  };
  const savedDarkModeSetting = localStorage.getItem("tradegpt-darkmode");

  useEffect(() => {
    const darkModePreference
      = savedDarkModeSetting !== null ? savedDarkModeSetting === "true" : true; // Default to true if no setting is saved
    document.body.className = darkModePreference ? "dark" : "light";
  }, [dispatch, savedDarkModeSetting]);

  return (
    <>
      {/* Global components like NotificationSnackbar */}
      <NotificationSnackbar
        open={notification.open}
        onClose={handleSnackbarClose}
        message={notification.message}
        severity={notification.severity}
        horizontal={notification.horizontal}
      />
      {/* Main application content */}
      {children}
    </>
  );
};

export default AppWrapper;
